import React from 'react';
import { IoIosClose } from 'react-icons/io';
import style from './button.module.css';

const GeneralModalClosingBtn = ({onClick}) => {
  return (
    <button title='סוגר חלונית' className={style.closingModalBtn} onClick={onClick}>
        <IoIosClose fontSize={60} color={'#4D4D4D'}/>
    </button>
  )
}

export default GeneralModalClosingBtn