import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SectionTitle from './components/SectionTitle';
import PermitRquestForm from './components/form/PermitRequestForm';
import TopDeclaration from './components/TopDeclaration';
import ConfirmModal from '../../components/modal/ConfirmModal';
import { usePermitRequestContext } from '../../contexts/PermitRequestContext';
import { useProjectConfigContext } from '../../contexts/ProjectConfigContext';
import LoadingModal from '../../components/loadingModal/LoadingModal';
import Style from './components/permitRequest.module.css';
import DangerAlert from '../../components/Alerts/DangerAlert';
import SuccessModal from './components/successModal/SuccessModal';

const PermitRequestPage = () => {

    const { isModalOpen, setisModalOpen, values, confModalState, submitPermitRequest, pageState } = usePermitRequestContext();
    const { setProjectId } = useProjectConfigContext();
    const params = useParams();
    const projectId = params.projectId;
    let modal = '';
    const title = 'טופס בקשה לתו חנייה';
    
    useEffect(() => {

        setProjectId(projectId);
    }, [projectId]);


    let titlePermitDetails = 'פרטי המבקש';
    let textPermitDetails = 'מלאו את הפרטים הבאים בצורה מפורטת שיסייעו לנו בטיפול בפנייה. *שדות חובה';

    if (isModalOpen) {
        modal = (
            <ConfirmModal
                title={'האם אתה בטוח?'} body={'אין אפשרות לבטל בקשה לאחר שנשלחה.'}
                apporveFunc={() => onApproval(values)} closingFunc={onCancel}
                cancelFunc={onCancel} />
        );
    }

    return (
        <>
            {confModalState.isModalOpen ? <SuccessModal /> : ''}
            {modal}
            {pageState.isLoading &&  <LoadingModal />}  
            {pageState.hasMessage ? <DangerAlert message={pageState.message} /> : ''}
            <TopDeclaration projectId={projectId} />
            <legend className={Style.smallHeaderTitle}>{title}</legend>
            <SectionTitle title={titlePermitDetails} text={textPermitDetails} />
            <PermitRquestForm onSubmission={onSubmission} />          
        </>
    );

    function onSubmission() {
        setisModalOpen(true);
    }

    function onCancel() {
        setisModalOpen(false);
    }

    function onApproval(valus) {

        setisModalOpen(false);
        submitPermitRequest(valus);
    }
}

export default PermitRequestPage;