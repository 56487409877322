import * as yup from 'yup';
import validatorPersonId from '../helpers/validates/validatorPersonId';
import { onlyEnglishAndHebrewAndSpace, onlyNumbersRegExp, phoneRegExp } from '../helpers/regExps';
import { isNumeric } from '../helpers/validates/isNumeric';
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "application/pdf"];
const MAX_FILE_SIZE = 5942880; // 5*1024*1024 bytes
const ONLY_DIGIT_PERMITED_MESSAGE = 'יכול להכיל ספרות בלבד';
const ONLY_LETTERS_HEBREW_OR_ENGLISH_PERMITED_MESSAGE = 'יכול להכיל אותיות בעברית ,אנגלית ורווח בלבד.';

const MAX_FILE_SIZE_MESSAGE = 'קובץ גדול מידי, ניתן לעלות עד 5 MB';
const TYPE_FILE_MESSAGE = `ניתן לעלות קבצים מסוג: ${SUPPORTED_FORMATS} בלבד.`;
const REQUIRED_FIELD_MESSAGE = 'שדה חובה!';
const MOBILE_FORMAT_MESSAGE = 'מספר סלולרי אינו תקין';

export const requestSchema = yup.object().shape({
    carId: yup.string()
        .matches(onlyNumbersRegExp, ONLY_DIGIT_PERMITED_MESSAGE)
        .min(5, 'חייב להכיל לפחות 5 ספרות')
        .max(8, 'לכל היותר 8 ספרות')
        .required(REQUIRED_FIELD_MESSAGE),
    vColor: yup.string()
        .notOneOf([-1, '-1'], 'אנא בחר צבע')
        .required(REQUIRED_FIELD_MESSAGE),
    vType: yup.string()
        .notOneOf([-1, '-1'], 'אנא בחר דגם רכב')
        .required(REQUIRED_FIELD_MESSAGE),
    scanCarId: yup.mixed()
        .nonNullable()
        .required(REQUIRED_FIELD_MESSAGE)
        .test(
            "FILE_SIZE",
            MAX_FILE_SIZE_MESSAGE,
            (value) => {

                return !value || (value && value.size <= MAX_FILE_SIZE)
            }
        ).test(
            "FILE_FORMAT",
            TYPE_FILE_MESSAGE,
            (value) => {

                return !value || (value && SUPPORTED_FORMATS.includes(value?.type))
            }
        ),
    scanWorkStatment: yup.mixed()
        .when("ownershipCarType", (ownershipCarType, schema) => {

            if (ownershipCarType == 1 || ownershipCarType == '1') {
                return schema.notRequired();
            }

            return (
                schema
                    .nonNullable()
                    .required(REQUIRED_FIELD_MESSAGE)
                    .test(
                        "FILE_SIZE",
                        MAX_FILE_SIZE_MESSAGE,
                        (value) => {

                            return !value || (value && value.size <= MAX_FILE_SIZE)
                        }
                    )
                    .test(
                        "FILE_FORMAT",
                        TYPE_FILE_MESSAGE,
                        (value) => {

                            return !value || (value && SUPPORTED_FORMATS.includes(value?.type))
                        }
                    )
            );
        }),
    privateName: yup.string()
        .required(REQUIRED_FIELD_MESSAGE),
    familyName: yup.string()
        .required(REQUIRED_FIELD_MESSAGE),
    id: yup.string()
        .required(REQUIRED_FIELD_MESSAGE)
        .test('valid-id', 'מספר ת.ז אינו חוקי. צריך להכיל ספרת ביקורת', value => validatorPersonId(value)),
    scanId: yup.mixed()
        .nonNullable()
        .required(REQUIRED_FIELD_MESSAGE)
        .test(
            "FILE_SIZE",
            MAX_FILE_SIZE_MESSAGE,
            (value) => {

                return !value || (value && value.size <= MAX_FILE_SIZE)
            })
        .test(
            "FILE_FORMAT",
            TYPE_FILE_MESSAGE,
            (value) => {

                return !value || (value && SUPPORTED_FORMATS.includes(value?.type))
            }
        ),
    streetId: yup.string()
        .notOneOf([-1, '-1'], 'אנא בחר רחוב')
        .required(REQUIRED_FIELD_MESSAGE),
    house: yup.string()
        .test(
            "house",
            'מספר בית חייב להיות מספר שגדול מאפס',
            (value) => {

                if (!value || !isNumeric(value)) {
                    return false;
                }

                let num = Number(value);

                return num > 0;
            }
        )
        .required(REQUIRED_FIELD_MESSAGE)
        .matches(onlyNumbersRegExp, ONLY_DIGIT_PERMITED_MESSAGE),
    appartment: yup.string()

        .test(
            "appartment",
            'מספר דירה חייב להיות מספר שגדול מאפס',
            (value) => {

                if (!value) return true;

                if (!isNumeric(value)) {
                    return false;
                }

                let num = Number(value);

                return num > 0;
            }
        )
        .matches(onlyNumbersRegExp, ONLY_DIGIT_PERMITED_MESSAGE),
    email: yup.string()
        .email('כתובת אימייל לא חוקית')
        .required(REQUIRED_FIELD_MESSAGE),
    mobile: yup.string()
        .required(REQUIRED_FIELD_MESSAGE)
        .matches(phoneRegExp, MOBILE_FORMAT_MESSAGE),
    phone: yup.string()
        .matches(phoneRegExp, MOBILE_FORMAT_MESSAGE),
    mailCity: yup.string()
        .when("additionalAddress", (additionalAddress, schema) => {

            if (additionalAddress.length > 0 && additionalAddress[0] === 0) {
                return schema;
            }
            return schema
                .matches(onlyEnglishAndHebrewAndSpace, ONLY_LETTERS_HEBREW_OR_ENGLISH_PERMITED_MESSAGE)
                .required(REQUIRED_FIELD_MESSAGE);
        }),
    mailStreet: yup.string()
        .when("additionalAddress", (additionalAddress, schema) => {

            if (additionalAddress.length > 0 && additionalAddress[0] === 0) {
                return schema;
            }
            return schema
                .matches(onlyEnglishAndHebrewAndSpace, ONLY_LETTERS_HEBREW_OR_ENGLISH_PERMITED_MESSAGE)
                .required(REQUIRED_FIELD_MESSAGE);
        }),
    mailHouse: yup.string()
        .when("additionalAddress", (additionalAddress, schema) => {

            if (additionalAddress.length > 0 && additionalAddress[0] === 0) {
                return schema;
            }

            return schema
                .matches(onlyNumbersRegExp, ONLY_DIGIT_PERMITED_MESSAGE)
                .required(REQUIRED_FIELD_MESSAGE);
        }),
    mailAppartment: yup.string()
        .when("additionalAddress", (additionalAddress, schema) => {

            if (additionalAddress.length > 0 && additionalAddress[0] === 0) {
                return schema;
            }

            return schema
                .matches(onlyNumbersRegExp, ONLY_DIGIT_PERMITED_MESSAGE)
                .required(REQUIRED_FIELD_MESSAGE);
        }),
    mailMikod: yup.string()
        .when("additionalAddress", (additionalAddress, schema) => {

            if (additionalAddress.length > 0 && additionalAddress[0] === 0) {
                return schema;
            }

            return schema
                .matches(/^[0-9]+$/, ONLY_DIGIT_PERMITED_MESSAGE)
                .required(REQUIRED_FIELD_MESSAGE);
        }),
    agreeTakanon: yup.string()
        .test(
            "agreeTakanon_",
            'חובה לאשר את התנאים וההגבלות',
            (value) => value == 1
        ),
    extraFile4: yup.mixed()
        .when("isRentApp", (isRentApp, schema) => {

            if (isRentApp == 0 || isRentApp == '0') {
                return schema.notRequired();
            }

            return (
                schema
                    .nonNullable()
                    .required(REQUIRED_FIELD_MESSAGE)
                    .test(
                        "FILE_SIZE",
                        MAX_FILE_SIZE_MESSAGE,
                        (value) => {

                            return !value || (value && value.size <= MAX_FILE_SIZE)
                        }
                    )
                    .test(
                        "FILE_FORMAT",
                        TYPE_FILE_MESSAGE,
                        (value) => {

                            return !value || (value && SUPPORTED_FORMATS.includes(value?.type))
                        }
                    )
            );
        }),
    extraFile5: yup.mixed()
        .when("isRentApp", (isRentApp, schema) => {

            if (isRentApp == 0 || isRentApp == '0') {
                return schema.notRequired();
            }

            return (
                schema
                    .nonNullable()
                    .required(REQUIRED_FIELD_MESSAGE)
                    .test(
                        "FILE_SIZE",
                        MAX_FILE_SIZE_MESSAGE,
                        (value) => {

                            return !value || (value && value.size <= MAX_FILE_SIZE)
                        }
                    )
                    .test(
                        "FILE_FORMAT",
                        TYPE_FILE_MESSAGE,
                        (value) => {

                            return !value || (value && SUPPORTED_FORMATS.includes(value?.type))
                        }
                    )
            );
        }),
    extraFile2: yup.mixed()
        .when("hasBusiness", (hasBusiness, schema) => {

            if (hasBusiness == 0 || hasBusiness == '0') {
                return schema.notRequired();
            }

            return (
                schema
                    .nonNullable()
                    .required(REQUIRED_FIELD_MESSAGE)
                    .test(
                        "FILE_SIZE",
                        MAX_FILE_SIZE_MESSAGE,
                        (value) => {

                            return !value || (value && value.size <= MAX_FILE_SIZE)
                        }
                    )
                    .test(
                        "FILE_FORMAT",
                        TYPE_FILE_MESSAGE,
                        (value) => {

                            return !value || (value && SUPPORTED_FORMATS.includes(value?.type))
                        }
                    )
            );
        }),
    extraFile3: yup.mixed()
        .when("hasBusiness", (hasBusiness, schema) => {

            if (hasBusiness == 0 || hasBusiness == '0') {
                return schema.notRequired();
            }

            return (
                schema
                    .nonNullable()
                    .required(REQUIRED_FIELD_MESSAGE)
                    .test(
                        "FILE_SIZE",
                        MAX_FILE_SIZE_MESSAGE,
                        (value) => {

                            return !value || (value && value.size <= MAX_FILE_SIZE)
                        }
                    )
                    .test(
                        "FILE_FORMAT",
                        TYPE_FILE_MESSAGE,
                        (value) => {

                            return !value || (value && SUPPORTED_FORMATS.includes(value?.type))
                        }
                    )
            );
        }),

});
