import React, { useEffect, useState } from 'react';
import Style from '../permitRequest.module.css';
import { usePermitRequestContext } from '../../../../contexts/PermitRequestContext';
import InputGroup from '../../../../components/form/InputGroup';
import RadioBtn from '../../../../components/form/radio/RadioBtn';
import RadioBtnsContainer from '../../../../components/form/radio/RadioBtnsContainer';

function AdditionalAddress() {

    const { handleChange, errors, values, touched, handleBlur, setFieldValue } = usePermitRequestContext();
    const [radioBtnState, setChecked] = useState([0, 1]);

    useEffect(() => {

        setChecked([values.additionalAddress == 1 ? true : false, values.additionalAddress == 0 ? true : false]);
    }, [values.additionalAddress]);


    return (
        <div className={Style.section}>
            <div className='row'>
                <div className='col-sm'>
                    <RadioBtnsContainer label={'האם קיימת כתובת שונה למשלוח תו חנייה?'}>
                        <RadioBtn
                            isChecked={radioBtnState[0]}
                            label='כן'
                            value={1}
                            onChange={() => { }}
                            onClick={handleClick}
                            id="additionalAddressYes"
                            name='additionalAddress'
                        />
                        <RadioBtn
                            isChecked={radioBtnState[1]}
                            label='לא'
                            value={0}
                            onChange={() => { }}
                            onClick={handleClick}
                            id="additionalAddressNo"
                            name='additionalAddress'
                        />
                    </RadioBtnsContainer>
                </div>
                {values.additionalAddress === 1 ? (
                    <>
                        <div className='row'>
                            <div className='col-md-6 col-lg-3'>
                                <InputGroup
                                    name={'mailCity'}
                                    label={'עיר למשלוח'}
                                    value={values.mailCity}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    isRequired={true}
                                    errorMsg={(errors.mailCity && touched.mailCity) ? errors.mailCity : undefined} />
                            </div>
                            <div className='col-md-3 col-lg-3'>
                                <InputGroup
                                    name={'mailStreet'}
                                    label={'רחוב'}
                                    value={values.mailStreet}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    isRequired={true}
                                    errorMsg={(errors.mailStreet && touched.mailStreet) ? errors.mailStreet : undefined} />
                            </div>
                            <div className='col-md-6 col-lg-2'>
                                <InputGroup
                                    name={'mailHouse'}
                                    label={'בית'}
                                    value={values.mailHouse}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    isRequired={true}
                                    errorMsg={(errors.mailHouse && touched.mailHouse) ? errors.mailHouse : undefined} />
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-md-6 col-lg-2'>
                                <InputGroup
                                    name={'mailAppartment'}
                                    label={'דירה'}
                                    value={values.mailAppartment}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    isRequired={true}
                                    errorMsg={(errors.mailAppartment && touched.mailAppartment) ? errors.mailAppartment : undefined} />
                            </div>
                            <div className='col-md-6 col-lg-2'>
                                <InputGroup
                                    name={'mailMikod'}
                                    label={'מיקוד'}
                                    value={values.mailMikod}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    isRequired={true}
                                    errorMsg={(errors.mailMikod && touched.mailMikod) ? errors.mailMikod : undefined} />
                            </div>

                        </div>
                    </>) : ''
                }
            </div>
        </div>
    );

    function handleClick(e) {

        setFieldValue('additionalAddress', Number(e.currentTarget.value));
    }
}

export default AdditionalAddress;