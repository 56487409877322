import React from 'react';
import Style from '../permitRequest.module.css';
import SelectStreet from '../../../../components/form/select/SelectStreet';
import { usePermitRequestContext } from '../../../../contexts/PermitRequestContext';
import InputGroup from '../../../../components/form/InputGroup';

function ContactDetails() {

    const { handleChange, errors, values, touched, handleBlur, setFieldValue, setFieldTouched } = usePermitRequestContext();

    return (
        <div className={Style.section}>
            <div className='row align-items-end'>
                <div className='col-md-6 col-lg-4'>
                    <SelectStreet
                        name={'streetId'}
                        value={values.streetId}
                        isRequired={true}
                        handleChange={option => setFieldValue('streetId', option.value)}
                        handleBlur={e => {
                            handleBlur(e);
                            setFieldTouched('streetId', true);
                        }}
                        errorMsg={(errors.streetId && touched.streetId) ? errors.streetId : undefined} />
                </div>

                <div className='col-md-3 col-lg-2'>
                <InputGroup
                        name={'house'}
                        label={'בית'}
                        value={values.house}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        isRequired={true}
                        errorMsg={(errors.house && touched.house) ? errors.house : undefined}
                    />
                </div>
                <div className='col-md-3 col-lg-2'>
                <InputGroup
                        name={'appartment'}
                        label={'דירה'}
                        value={values.appartment}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorMsg={(errors.appartment && touched.appartment) ? errors.appartment : undefined}
                    />

                </div>

            </div>

            <div className='row align-items-end'>
                <div className='col-md-6 col-lg-4'>
                <InputGroup
                        name={'email'}
                        label={'דואר אלקטרוני'}
                        value={values.email}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        isRequired={true}
                        errorMsg={(errors.email && touched.email) ? errors.email : undefined}
                    />
                  
                </div>
            </div>

            <div className='row align-items-end'>
                <div className='col-md-6 col-lg-4'>
                <InputGroup
                    name={'mobile'}
                    label={'טלפון נייד'}
                    value={values.mobile}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    isRequired={true}
                    errorMsg={(errors.mobile && touched.mobile) ? errors.mobile : undefined} />
                  
                </div>
                <div className='col-md-6 col-lg-4'>
                <InputGroup
                    name={'phone'}
                    label={'טלפון נוסף'}
                    value={values.phone}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorMsg={(errors.phone && touched.phone) ? errors.phone : undefined}
                    />
                
                </div>
            </div>
        </div>
    );
}

ContactDetails.defaultProps = {
    isChecked: false
}

export default ContactDetails;