import { axiosCitizenSticker } from "./api";
import { useProjectConfigContext } from "../contexts/ProjectConfigContext";

export default function useAddPermitRequestApi(){

    const { authState } = useProjectConfigContext();
    
    async function addPermitRequestApi(formData){
     
        const config = {
            headers: { Authorization: `Bearer ${authState.token}` }
        };

        const reqToSend = JSON.parse(JSON.stringify(formData));
        //console.log(`reqToSend: ${reqToSend}`)
        const response = await axiosCitizenSticker.post('/addCitizenSticker', reqToSend, config);
        return await response.data;
    }

    return addPermitRequestApi;
}