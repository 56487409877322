
export default function TopDeclaration({ projectId }) {

    let message = '';

    if (projectId == 95) {

        message = (
            <>
                <span>הטופס מיועד אך ורק למי שאין ברשותו תו חניה.</span>
                <br />
                <span>למי שיש ברשותו תו, אינו נדרש להגיש בקשה.</span>
            </>
        );

    } else if (projectId == 26) {

        message = (
            <>
                <span>1. התו יכנס לתוקף תוך 24 שעות מקבלת האישור במייל.</span>
                <br />
                <span>2. תושב/ת יקר/ה תו תושב ללא עלות רק מגיל 67.</span>
                <br />
                <span>3. תו התושב יאושר רק לרכב פרטי מסוג M1.</span>
            </>
        );

    } else if (projectId == 51) {

        message = (
            <>
                <span>1. אישור לתו יינתן בתום תשלום האגרה, והשלמת הגשת כל המסמכים הנדרשים.</span>
                <br />
                <span>2. התו יכנס לתוקף תוך 24 שעות מקבלת האישור.</span>
            </>
        );

    }

    return (
        <div className="row">
            <div className="col-md-12 col-lg-10">
                {message ? (
                    <div className="alert alert-secondary">
                        {message}
                    </div>
                ) : ''}
            </div>
        </div>
    );
}
