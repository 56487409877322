import React from 'react';
import Style from '../permitRequest.module.css';
import InputGroup from '../../../../components/form/InputGroup';
import PermitRequestType from './PermitRequestType';
import { usePermitRequestContext } from '../../../../contexts/PermitRequestContext';

function PersonDetails({}) {

    const { handleChange, errors, values, touched, handleBlur, setFieldValue } = usePermitRequestContext();
    const classes = Style.section + ' mb-4';

    return (
        <section className={classes}>
            <div className='row'>
                <div className='col-md-6 col-lg-4'>
                    {touched.privateName}
                    <InputGroup
                    type={'text'} 
                    name={'privateName'} 
                    label={'שם פרטי'}
                    value={values.privateName} 
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    isRequired={true}
                    errorMsg={(errors.privateName && touched.privateName) ? errors.privateName : undefined}
                    />
                    
                </div>
                <div className='col-md-6 col-lg-4'>
                <InputGroup
                    type={'text'} 
                    name={'familyName'} 
                    label={'שם משפחה'}
                    value={values.familyName} 
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorMsg={(errors.familyName && touched.familyName) ? errors.familyName : undefined}
                    isRequired={true}
                    />
                </div>
                
            </div>
            <div className='row'>
                <div className='col-md-6 col-lg-4'>
                <InputGroup 
                    name={'id'} 
                    label={'תעודת זהות'}
                    type='number'
                    value={values.id} 
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    isRequired={true}
                    errorMsg={(errors.id && touched.id) ? errors.id : undefined}
                    />
                </div>
                <div className='col-md-6 col-lg-4'>
                <InputGroup
                    type={'file'} 
                    name={'scanId'} 
                    label='צילום תעודת זהות וספח'
                    handleBlur={handleBlur}
                    handleChange={(event) => {
                        setFieldValue('scanId', event.currentTarget.files[0])
                    }}
                    isRequired={true}
                    errorMsg={(errors.scanId && touched.scanId) ? errors.scanId : undefined}
                    />
                </div>
            </div>

            <PermitRequestType />
        </section>
    );
}

PersonDetails.defaultProps = {
    isChecked: false
}

export default PersonDetails;