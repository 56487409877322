import React from 'react';
import SectionTitle from '../SectionTitle';
import Style from '../permitRequest.module.css';
import SelectInput from '../../../../components/form/select/SelectInput';
import InputGroup from '../../../../components/form/InputGroup';
import { usePermitRequestContext } from '../../../../contexts/PermitRequestContext';
import { useProjectConfigContext } from '../../../../contexts/ProjectConfigContext';


function CreditPaymentDetails(){
    const {projectConfigState} = useProjectConfigContext();
    const { handleChange, errors, values, touched, handleBlur } = usePermitRequestContext();
    const payment = projectConfigState.tdsConfig.payment;
   
    const title = `פרטי התשלום(${payment} ₪)`;
    const text = 'מלאו את הפרטים הבאים בצורה מפורטת שיסייעו לנו בטיפול בפנייה';

    const years = [
        {value:-1, name:'צריך לבחור'},
        {value:'24', name:'2024'},
        {value:'25', name:'2025'},
        {value:'26', name:'2026'},
        {value:'27', name:'2027'},
        {value:'28', name:'2028'},
        {value:'29', name:'2029'},
        {value:'30', name:'2030'}
    ];

    const months = [
        {value:-1, name:'צריך לבחור'},
        {value:'01', name:'01'},
        {value:'02', name:'02'},
        {value:'03', name:'03'},
        {value:'04', name:'04'},
        {value:'05', name:'05'},
        {value:'06', name:'06'},
        {value:'07', name:'07'},
        {value:'08', name:'08'},
        {value:'09', name:'09'},
        {value:'10', name:'10'},
        {value:'11', name:'11'},
        {value:'12', name:'12'}
    ];


    return (
        <div className={Style.section}>
            <SectionTitle title={title} text={text}/>
            <div className='row'>
                <div className='col-sm-3'>
                    <InputGroup
                        name={'cardNumber'} 
                        label={'מספר כרטיס אשראי'} 
                        value={values.cardNumber}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        isRequired={true}
                        errorMsg={(errors.cardNumber && touched.cardNumber) ? errors.cardNumber : undefined}
                    />
                </div>
                <div className='col-sm-2'>
                    <SelectInput
                        name={'cardExpireDateYY'} 
                        label={'תוקף שנה'} 
                        value={values.cardExpireDateYY}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        selects={years}
                        isRequired={true}
                    />
                </div>
                <div className='col-sm-2'>
                    <SelectInput
                        name={'cardExpireDateMM'} 
                        label={'תוקף חודש'}
                        value={values.cardExpireDateMM}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        selects={months}
                        isRequired={true}
                    />
                </div>
                <div className='col-sm-1'>
                <InputGroup
                    name={'cardAddedNumber'} 
                    label={'CVV'} 
                    value={values.cardAddedNumber}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    isRequired={true}
                    errorMsg={(errors.cardAddedNumber && touched.cardAddedNumber) ? errors.cardAddedNumber : undefined}
                />
                </div>
            </div>
            <div className='row'>
                <div className='col-sm-3'>  
                    <InputGroup
                        name={'cardHolderId'} 
                        label={'תעודת זהות של העל הכרטיס'} 
                        value={values.cardHolderId}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        isRequired={true}
                        errorMsg={(errors.cardHolderId && touched.cardHolderId) ? errors.cardHolderId : undefined}
                    /> 
                </div>
            </div>
        </div>
    );
}

CreditPaymentDetails.defaultProps = {
    isChecked: false
}

export default CreditPaymentDetails;