import { createContext, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { useFormik } from "formik";
import { permitRequestData } from "../data/permitRequestData";
import { requestSchema } from "../validations/permitRequestValidation";
import PermitRequestPage from "../pages/permitRequest/PermitRequestPage";
import { FORM_ACTION, successAddRequestModalReduser } from "../reducers/successAddRequestModalReduser";
import { initConfirmationModal } from "../data/initConfirmationModal.data";
import { useNavigate } from "react-router-dom";
import useAddPermitRequestApi from "../api/useAddPermitRequestApi";
import { convertFileToSendToServer } from '../helpers/convertors';
import { COMPONENT_STATE_ACTION, useComponentState } from '../hooks/useComponentState';
import translateErrorCode from "../helpers/errors/translateErrorCode";


export const PermitRequestContext = createContext();

export const PermitRequestProvider = () => {

    const navigate = useNavigate();
    const [isModalOpen, setisModalOpen] = useState(false);
    const [pageState, pageStateDispatch] = useComponentState();
    const addPermitRequestApi = useAddPermitRequestApi();
    const [confModalState, confModalStateDispatch] = useReducer(successAddRequestModalReduser, initConfirmationModal);
    const { errors, values, touched, isValid, handleSubmit, handleBlur, 
        setFieldValue, setFieldTouched, validateField, validateForm } = useFormik({
        initialValues: { ...permitRequestData },
        validateOnMount: true,
        validateOnChange: false,
        validationSchema: requestSchema,
        validateOnBlur: true,
        onSubmit: () => setisModalOpen(true),
    });

    useEffect(() => {
        validateForm();
    }, [values.vColor, values.vType, values.streetId]);


    const handleChange = useMemo(() => {
        return (event) => {
            setFieldValue(event.target.name, event.target.value)
        };
    }, []);

    return (
        <PermitRequestContext.Provider value={
            {
                isModalOpen, setisModalOpen,
                handleChange, errors, values, touched, isValid, handleSubmit, handleBlur, setFieldValue,
                setFieldTouched, validateField,
                confModalState, closeConfModal, openConfModal, submitPermitRequest,
                pageState
            }
        }>
            <PermitRequestPage />
        </PermitRequestContext.Provider>
    );


    function closeConfModal() {

        confModalStateDispatch({
            type: FORM_ACTION.CLOSE_MODAL
        });

        navigate(`/home/${values.projectId}`);
    }

    function openConfModal() {

        confModalStateDispatch({
            type: FORM_ACTION.OPEN_MODAL
        });
    }

    async function submitPermitRequest(values) {

        try {

            pageStateDispatch({
                type: COMPONENT_STATE_ACTION.LOADING_STATE
            });

            const data = { ...values };

            data.scanId = await convertFileToSendToServer(values.scanId);
            data.scanCarId = await convertFileToSendToServer(values.scanCarId);
            data.scanWorkStatment = await convertFileToSendToServer(values.scanWorkStatment);
            data.extraFile1 = await convertFileToSendToServer(values.extraFile1);
            data.extraFile2 = await convertFileToSendToServer(values.extraFile2);
            data.extraFile3 = await convertFileToSendToServer(values.extraFile3);
            data.extraFile4 = await convertFileToSendToServer(values.extraFile4);
            data.extraFile5 = await convertFileToSendToServer(values.extraFile5);

            const response = await addPermitRequestApi(data);

            if (response.status === 1) {

                const asmachta = response.asmachta;
                const permitRequestId = response.permitRequestId;
                const amountPayed = response.amountPayed;

                confModalStateDispatch({
                    type: FORM_ACTION.UPDATE_DATA,
                    asmachta: asmachta,
                    permitRequestId: permitRequestId,
                    amountPayed: amountPayed,
                    isModalOpen: true,
                });
            }
        } catch (error) {

            console.log('Error', error);
            let errorMsg = 'בעיה כללית בשרת';

            if (error.response) {

                errorMsg = translateErrorCode(error);
                console.log(`Error - ${errorMsg}`);
            } else if (error.request) {
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            
            onFocusAlert();
            pageStateDispatch({
                type: COMPONENT_STATE_ACTION.FAIL_STATE,
                message: errorMsg
            });
        }

        pageStateDispatch({
            type: COMPONENT_STATE_ACTION.NOT_LOADING_STATE
        });
    }

    function onFocusAlert() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

export const usePermitRequestContext = () => useContext(PermitRequestContext);