import React from 'react';
import GeneralModal from '../UI/modal/GeneralModal';

let btnStyle = {width: '5rem'};

const ConfirmModal = ({title, body, closingFunc, apporveFunc, cancelFunc}) => {

  return (
    <GeneralModal 
        closingFunc={closingFunc}
        title={title}
        body={body}
        footer={(
          <>
            <button style={btnStyle} className='btn btn-success' onClick={e => apporveFunc(e)}>אישור</button>
            <button style={btnStyle} className='btn btn-secondary' onClick={cancelFunc}>ביטול</button>
          </>
        )}
    />
  )
}

export default ConfirmModal