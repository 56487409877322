import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { initProjectConfigData } from '../data/initProjectConfigData';
import { AUTH_ACTION, authReducer } from '../reducers/AuthReducer';
import { axiosPrivate } from '../api/api';
import ResponseStatus from '../types/ResponseStatus';
import { translateHtmlUnicodeToChar } from '../helpers/language/TranslateHtmlUncodeToChar';
import translateErrorCode from '../helpers/errors/translateErrorCode';
import { useNavigate } from 'react-router-dom';

export const PROJECT_CONFIG_ACTION = {
    SET_PROJECT_ID: 'SET_PROJECT_ID',
    SET_DATA: 'SET_DATA',
}

function reducer(state, action) {

    switch (action.type) {
        case PROJECT_CONFIG_ACTION.SET_PROJECT_ID:
            return { ...state, projectId: action.payload.projectId };
        case PROJECT_CONFIG_ACTION.SET_DATA:

            const data = action.payload.data;
            const tdsConfig = data.taveiDayarSiteConfig;
            const projectConfig = data.projectConfig;

            return {
                ...state,
                colors: data.colors,
                makers: data.makers,
                streets: data.streets,
                tdsConfig: tdsConfig,
                cityLogoPath: projectConfig.cityLogUrl,
                cityHebName: projectConfig.hebName,
                hasTopDeclaration: false,
                hasTakanon: false,
                hasAdditionalAddress: data.hasAdditionalAddress,
                extraFile: false,
                hasSerantKiriaBialikFilession: false,
                contryClubFile: false,
                extraApartmentFiles: false,
                extraBusinessFiles: false,
            };
        default:
            return state;
    }
}

const ONE_MINUTE = 1000 * 60;
const TWENTY_MINUTES = 1000 * 60 * 20;
const ONE_HOUR = 1000 * 60 * 60;

export const ProjectConfigContext = createContext();

export const ProjectConfigProvider = (props) => {

    const navigate = useNavigate();
    const [projectConfigState, projectConfigDispatch] = useReducer(reducer, initProjectConfigData);
    const [authState, authDispatch] = useReducer(authReducer, { token: '' });
    const [isProjectConfigLoading, setIsProjectConfigLoading] = useState(false);
    const [timeSpentOnPage, setTimeSpentOnPage] = useState(0); // in milliseconds

    function setToken(token) {

        authDispatch({
            type: AUTH_ACTION.SET_TOKEN,
            payload: { token: token }
        });
    }

    function setProjectId(projectId) {

        projectConfigDispatch({
            type: PROJECT_CONFIG_ACTION.SET_PROJECT_ID,
            payload: { projectId: projectId }
        });
    }

    async function loadProjectConfig() {

        let message = '';
        if (projectConfigState.projectId === -1) return;

        setIsProjectConfigLoading(true);
        try {

            const token = await getToken();

            const responseStatus = await getProjectConfigDetails(token);
        } catch (error) {

            console.log('Error', error);
            if (error.response) {

                message = translateErrorCode(error);
                console.log(`Error - ${message}`);
            } else if (error.request) {
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

            navigate('notFound');
        }

        setIsProjectConfigLoading(false);
    }

    async function getProjectConfigDetails(token) {

        const response = await axiosPrivate.get('/systemTables/portal-project-config', {
            headers: { Authorization: `Bearer ${token}` }
        });

        const data = response.data;

        if (!data?.status || !data?.data || !data.data?.portalDataConfigBean) {
            throw new Error("no data from server.");
        }
        if (data.status === ResponseStatus.FAILURE) {

            throw new Error("failure message - " + (data?.message) ? data.message : '');
        }

        const portalDataConfigBean = data.data.portalDataConfigBean;
        const projectConfig = portalDataConfigBean.projectConfig;

        if (portalDataConfigBean?.makers) {

            portalDataConfigBean.makers = portalDataConfigBean.makers.sort((a, b) => a.name.localeCompare(b.name));
        }

        if (portalDataConfigBean?.colors) {

            portalDataConfigBean.colors = portalDataConfigBean.colors.sort((a, b) => a.name.localeCompare(b.name));
        }

        if (portalDataConfigBean?.streets) {

            portalDataConfigBean.streets = portalDataConfigBean.streets.sort((a, b) => a.name.localeCompare(b.name));
        }

        if (projectConfig.hebName) {
            projectConfig.hebName = translateHtmlUnicodeToChar(projectConfig.hebName);
        }

        projectConfigDispatch({
            type: PROJECT_CONFIG_ACTION.SET_DATA,
            payload: { data: portalDataConfigBean }
        })

        return 'success';
    }

    async function getToken() {

        const data = { projectId: projectConfigState.projectId };
        let token = -1;

        try {

            const response = await axiosPrivate.post('/citizenSticker/citizen-login', data);

            if (response?.data?.data?.accessToken) {
                token = response.data.data.accessToken;
            }

            setToken(token);
            console.log(token)
            return token;
        } catch (error) {

            console.log(`error ${JSON.stringify(error)}`);
            return -1;
        }
    }

    async function refreshToken() {

        const token = getToken();

        if(token === -1) {
            navigate('notFound');
        }
    }

    useEffect(() => {

        loadProjectConfig();
    }, [projectConfigState.projectId]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeSpentOnPage(prevTime => prevTime + ONE_MINUTE); // increment by 1 second
        }, ONE_MINUTE); // every 1 second
        return () => clearInterval(intervalId); // cleanup
    }, []); // run only once on mount

    useEffect(() => {

        if(timeSpentOnPage === 0) {
            return;
        }

        if (timeSpentOnPage % TWENTY_MINUTES === 0) {
            refreshToken();
        }

        if(timeSpentOnPage > (ONE_HOUR + TWENTY_MINUTES)) {
            navigate('notFound');
        }

    }, [timeSpentOnPage]);

    return (
        <ProjectConfigContext.Provider value={{
            projectConfigState, projectConfigDispatch,
            setProjectId, loadProjectConfig, authState, isProjectConfigLoading, timeSpentOnPage
        }}>
            {props.children}
        </ProjectConfigContext.Provider>
    );
}

export const useProjectConfigContext = () => useContext(ProjectConfigContext);