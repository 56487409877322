import React from 'react';
import { usePermitRequestContext } from '../../../../contexts/PermitRequestContext';
import RadioBtn from '../../../../components/form/radio/RadioBtn';
import RadioBtnsContainer from '../../../../components/form/radio/RadioBtnsContainer';
import InputGroup from '../../../../components/form/InputGroup';

function StudentPermitType({radioBtnState, handleClick}){

    const { setFieldValue, values, errors, touched, handleBlur } = usePermitRequestContext();
   
    let inputFile = '';

    if(values.permitType === 2){

        inputFile = (

        <div className="row">
            <div className='col-4'> 
                <InputGroup 
                name={'extraFile1'}
                label={'צילום תעודת סטודנט'}
                value={values.extraFile1}
                isRequired={true}
                handleBlur={handleBlur}
                handleChange={(event) => {
                    setFieldValue('extraFile1', event.currentTarget.files[0])
                }}
                errorMsg={(errors.extraFile1 && touched.extraFile1) ? errors.extraFile1 : undefined}
                />   
            </div>
        </div>
        );
    }

    return (
        <>
        <div className="row"> 
            <div className='col-sm-2'>
                <RadioBtnsContainer label={'סטודנט?'}>
                        <RadioBtn 
                        isChecked={radioBtnState[2]}
                        label='לא'
                        value={0}
                        onChange={() => {}}
                        onClick={handleClick}
                        id="studentPermitYes"
                        name='permitTypeStudent'
                        />
                        <RadioBtn
                        isChecked={radioBtnState[3]}
                        label='כן' 
                        value={2}
                        onChange={() => {}}
                        onClick={handleClick}
                        id="studentPermitNo"
                        name='permitTypeStudent'
                        />
                    </RadioBtnsContainer> 
            </div>
        </div>

        {inputFile}
        </>
    );
}

export default StudentPermitType;