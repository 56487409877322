import React from 'react'
import GeneralModal from '../../../../components/UI/modal/GeneralModal';
import { usePermitRequestContext } from '../../../../contexts/PermitRequestContext';
import ConfirmationContent from './ConfirmationContent';

const SuccessModal = () => {

    const { closeConfModal } = usePermitRequestContext();

  return (
    <GeneralModal 
    closingFunc={closeConfModal}
    modalSize={'M'}
    title={'התהליך הסתיים בהצלחה'}
    body={<ConfirmationContent />}
    footer={(
      <>
        <button  className='btn btn-success' onClick={closeConfModal}>אישור</button>
      </>
    )}
/>
  )
}

export default SuccessModal;