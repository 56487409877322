import React from 'react';
import { FaCheck } from "react-icons/fa";
import Alert from './Alert';

const SuccessAlert = ({message}) => {

  return (
    <Alert message={message} iconEnd={<FaCheck />} type={'success'}/>
  )
}

export default SuccessAlert