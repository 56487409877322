import React from 'react';
import GeneralModal from '../../../../components/UI/modal/GeneralModal';
import OtpForm from './OtpForm';
import { useHomePageContext } from '../../../../contexts/HomePageContext';
import style from '../../homePage.module.css';
import DangerAlert from '../../../../components/Alerts/DangerAlert';
import { useComponentState } from '../../../../hooks/useComponentState';
import { ActionPortalTypes, OtpStatus } from '../../../../types/HomePagePortalTypes';
import AddFileForm from './AddFileForm';
import DisplayPermitIsCanceled from './DisplayPermitIsCanceled';
import DisplayPermitValidationDate from './DisplayPermitValidationDate';
import DisplayRequestStatus from './DisplayRequestStatus';

const OtpModal = () => {

    const { closeOtpModal, otpState, modalState, sendOtpCode } = useHomePageContext();
    
    let alert = '';

    if(modalState.hasMessage) {
        alert = <DangerAlert message={modalState.message}/>;
    }

    let body = (
        <>
            {alert}
            <OtpForm />
        </>
    );

    if (otpState.otpStatus === OtpStatus.OTP_SUCCESS) {

        body = (
            <>
                {alert}
                <OtpForm />
            </>
        );

        if(otpState.portalAction === ActionPortalTypes.ADD_FILE_TO_REQUEST) {
            body = (
                <>
                    {alert}
                    <AddFileForm />
                </>
            );
        }else if(otpState.portalAction === ActionPortalTypes.CANCEL_PERMIT) {
            body = (
                <>
                    {alert}
                    <DisplayPermitIsCanceled />
                </>
            );
        }else if(otpState.portalAction === ActionPortalTypes.CHECK_PEMIT_VALIDITY) {
            body = (
                <>
                    {alert}
                    <DisplayPermitValidationDate />
                </>
            );
        }else if(otpState.portalAction === ActionPortalTypes.CHECK_REQUEST_STATUS) {
            body = (
                <>
                    {alert}
                    <DisplayRequestStatus />
                </>
            );
        }
    }

    return (
        <GeneralModal
            closingFunc={() => closeOtpModal()}
            title={otpState.modalTitle}
            body={(
                <div className={style.otpBody}>
                    {body}
                </div>
            )}
            hasFooter={false}
        />
    );


    function showModalAlert(message, status) {

    }

    function handleSendOtpCode() {

        sendOtpCode();
    }

    function removeModalAlert() {

    }
}

export default OtpModal