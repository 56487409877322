import { getBase64 } from "./getBase64";

export async function convertFileToSendToServer(file) {

    const result = {
        content: '',
        name: '',
        size: 0,
        contentType: ''
    }
    
    if(!file) {

        return result;
    }

    let base64 = '';
    base64 = await getBase64(file);

    result.content = base64;
    result.contentType = file.type;
    result.size = file.size;
    result.name = file.name;

    return result;
}