import React, { useEffect, useState } from 'react';
import style from './homePage.module.css';
import ActionBoxContainer from './components/ActionBoxContainer';
import { useProjectConfigContext } from '../../contexts/ProjectConfigContext';
import { useParams } from 'react-router-dom';
import OtpModal from './components/otpModal/OtpModal.jsx';
import { useHomePageContext } from '../../contexts/HomePageContext';
import DangerAlert from '../../components/Alerts/DangerAlert.jsx';
import SuccessAlert from '../../components/Alerts/SuccessAlert.jsx';
import LoadingModal from '../../components/loadingModal/LoadingModal.jsx';

const HomePage = () => {
    const { setProjectId, projectConfigState, isProjectConfigLoading, timeSpentOnPage } = useProjectConfigContext();
    const { otpState, closeOtpModal, homePageState } = useHomePageContext();
    
    const params = useParams();
    const projectId = params.projectId;
    let alert = '';

    useEffect(() => {
        setProjectId(projectId);
    }, [projectId]);

    if(homePageState.hasMessage) {

        alert = <DangerAlert message={homePageState.message}/>;

        if(homePageState.isSuccess) {
            alert = <SuccessAlert message={homePageState.message}/>;
        } 
    }

    return (
        <>
            {isProjectConfigLoading ? <LoadingModal /> : '' }
            {otpState.isOtpModalOpen ? <OtpModal closingFunc={closeOtpModal} /> : ''}
            {alert}
            <div className={style.homePage}>
                <ActionBoxContainer />
            </div>
        </>
    );
}

export default HomePage;