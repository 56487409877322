import React from 'react'
import RadioBtnsContainer from '../../../../components/form/radio/RadioBtnsContainer';
import RadioBtn from '../../../../components/form/radio/RadioBtn';

const GoldenAgePermit = ({radioBtnState, handleClick}) => {
  return (
    <div className='row'> 
        <div className='col col-sm-4'>
            <RadioBtnsContainer label={'מעל גיל 67?'}>
                <RadioBtn 
                isChecked={radioBtnState[0]}
                label='לא'
                value={0}
                onChange={() => {}}
                onClick={handleClick}
                id="goldenPermitYes"
                name='permitTypeGolden'
                />
                <RadioBtn
                isChecked={radioBtnState[1]}
                label='כן' 
                value={1}
                onChange={() => {}}
                onClick={handleClick}
                id="goldenPermitNo"
                name='permitTypeGolden'
                />
            </RadioBtnsContainer>
        </div>
    </div>
  );
}

export default GoldenAgePermit