import React from 'react';
import Style from './alers.module.css';
import { FiAlertTriangle } from "react-icons/fi";
import { AiOutlineAlert } from "react-icons/ai";
import { GrStatusGood } from "react-icons/gr";
import { BsInfoCircle } from "react-icons/bs";
// GrStatusGood
const Alert = ({ type, message, iconEnd, style }) => {

    let classes = '';
    let iconEndSpan = '';

    classes = getFullBootstarpClass(type) + ' ' + Style.alert;
    iconEndSpan = getDefaultIconByClass(type);
    if(iconEnd != null) iconEndSpan = <span>{iconEnd}</span>;

    return (
        <div style={style} className={classes} role="alert">
            <span>{message}</span>
            {iconEndSpan}
        </div>
    );

    function getFullBootstarpClass(type) {
        let classes = "alert alert-primary";

        if(type === 'secondary') classes = "alert alert-secondary";
        if(type === 'success') classes = "alert alert-success";
        if(type === 'danger') classes = "alert alert-danger";
        if(type === 'warning') classes = "alert alert-warning";
        if(type === 'info') classes = "alert alert-info";
        if(type === 'light') classes = "alert alert-light";
        if(type === 'dark') classes = "alert alert-dark";

        return classes;
    }

    function getDefaultIconByClass(type) {
    
        if(type === 'secondary') return null;
        if(type === 'success') return <GrStatusGood size={20}/>;
        if(type === 'danger') return <FiAlertTriangle size={20}/>;
        if(type === 'warning') return <AiOutlineAlert size={20}/>;
        if(type === 'info') return <BsInfoCircle size={20}/>;
        if(type === 'light') return null;
        if(type === 'dark') return null;

        return null;
    }
}

export default Alert