import React, { useEffect, useState } from 'react';
import StudentPermitType from './StudentPermitType';
import { usePermitRequestContext } from '../../../../contexts/PermitRequestContext';
import GoldenAgePermit from './GoldenAgePermit';
import { useProjectConfigContext } from '../../../../contexts/ProjectConfigContext';

function PermitRequestType(){

    const { values, setFieldValue } = usePermitRequestContext();
    const {projectConfigState} = useProjectConfigContext();
    const [radioBtnState, setChecked] = useState([true, false]);
    let goldenPermit = '';
    let studentPermit = '';
 
    useEffect(() => {
        
        setChecked([
            (values.permitType === 0 || values.permitType === 1 || values.permitType === 2) ? true : false, 
            values.permitType === 1 ? true : false,
            (values.permitType === 0 || values.permitType === 1) ? true : false, 
            values.permitType === 2 ? true : false
        ]);
    }, [values.permitType]);

    if(projectConfigState.tdsConfig.gilHaZahavOption){

        goldenPermit = <GoldenAgePermit radioBtnState={radioBtnState} handleClick={handleClick} />
    }

    if(false && projectConfigState.tdsConfig.studentOption){

        studentPermit = (
            <StudentPermitType radioBtnState={radioBtnState} handleClick={handleClick}/>  
        );
    }
    
    return (
        <>
        {goldenPermit}
        {studentPermit}
        </>
    );
    
    function handleClick(e) {
        setFieldValue('permitType', Number(e.currentTarget.value));
    }
}

export default PermitRequestType;