import React from 'react';
import Style from '../permitRequest.module.css';
import { usePermitRequestContext } from '../../../../contexts/PermitRequestContext';

const TakanonCheckBox = () => {

    const { handleBlur, errors, touched, setFieldValue, handleSubmit } = usePermitRequestContext();

    const errorMsg = errors.agreeTakanon && touched.agreeTakanon ? errors.agreeTakanon : undefined;

    return (
        <div>
            <span className={Style.checkInputForm}>
                <input
                    onBlur={handleBlur}
                    onChange={event => setFieldValue('agreeTakanon', event.currentTarget.checked ? 1 : 0)}
                    id={`agreeTakanonid`}
                    name='agreeTakanon'
                    className="form-check-input"
                    type='checkbox'
                />
                <label
                    className="form-check-label"
                    htmlFor={`agreeTakanonid`}
                >
                    קראתי ואני מאשר את התנאים וההגבלות
                </label>
            </span>
            {errorMsg ? <div className={Style.errMsgTakanon}> {errorMsg} </div> : ''}
        </div>
    )
}

export default TakanonCheckBox