import React from 'react'; 
import Style from './permitRequest.module.css';


const SectionTitle = ({title, text}) => {

    return (
        <>
            <h4>{title}</h4>
            <p className={Style.smallHeaderText}>{text}</p>
            <hr className={Style.sectionTitleHrBlack} />
        </>
    );
}

export default SectionTitle;