import React, { useEffect } from 'react';
import { BsSend } from 'react-icons/bs'
import CreditPaymentDetails from './CreditPaymentDetails';
import AdditionalAddress from './AdditionalAddress';
import CarDetails from './CarDetails';
import PersonDetails from './PersonDetails';
import ContactDetails from './ContactDetails';
import Takanon from '../Takanon';
import { usePermitRequestContext } from '../../../../contexts/PermitRequestContext';
import ExtraFile from './extraFiles/ExtraFile';
import { useProjectConfigContext } from '../../../../contexts/ProjectConfigContext';

const PermitRequestForm = () => {

    const { projectConfigState } = useProjectConfigContext();
    const { setFieldValue, handleSubmit } = usePermitRequestContext();
    
    const payment = projectConfigState.tdsConfig.payment;
    const hasAdditionalAddress = projectConfigState.hasAdditionalAddress;
    const hasPayment = payment > 0 ? true : false; 

    useEffect(() => {

        setFieldValue('projectId', projectConfigState.projectId);
    }, [projectConfigState]);

    return (
        <>
        <form onSubmit={handleSubmit}>

            <PersonDetails />

            <CarDetails />

            <ContactDetails />

            {hasAdditionalAddress ? <AdditionalAddress /> : ''}

            <ExtraFile />

            {hasPayment ?  <CreditPaymentDetails /> : ''}

            <Takanon />
     
            <div className='row'>
                <div className='col-md-4 col-sm-12'>
                    <button type="submit" className="btn btn-lg btn-success">{'שלח טופס'} <BsSend /></button>
                </div>
            </div>
        </form>
        </>
    );
}

export default PermitRequestForm;