import React, { useEffect, useState } from 'react';
import Style from '../permitRequest.module.css';
import { usePermitRequestContext } from '../../../../contexts/PermitRequestContext';
import InputGroup from '../../../../components/form/InputGroup';
import RadioBtnsContainer from '../../../../components/form/radio/RadioBtnsContainer';
import RadioBtn from '../../../../components/form/radio/RadioBtn';
import SelectCarMaker from '../../../../components/form/select/SelectCarMaker';
import SelectCarColor from '../../../../components/form/select/SelectCarColor';
import OwnershipCarType from '../../../../types/OwnershipCarType';

function CarDetails() {

    const { handleChange, errors, values, touched, 
        handleBlur, setFieldValue, setFieldTouched, validateField } = usePermitRequestContext();
    const [radioBtnState, setChecked] = useState([true, false]);

    let inputWorkStatment = '';

    const classes = Style.section + ' mb-4';

    useEffect(() => {

        setChecked([values.ownershipCarType === OwnershipCarType.PRIVATE ? true : false, values.ownershipCarType === OwnershipCarType.COMPANY ? true : false]);
    }, [values.ownershipCarType]);


    if (values.ownershipCarType === OwnershipCarType.COMPANY) {

        inputWorkStatment = (
            <div className='col-md-6 col-lg-4'>
                <InputGroup
                    type={'file'}
                    name={'scanWorkStatment'}
                    label={'צילום הצהרה ממקום העבודה/מחברת הליסינג'}
                    handleBlur={handleBlur}
                    handleChange={(event) => {
                        setFieldValue('scanWorkStatment', event.currentTarget.files[0])
                    }}
                    isRequired={true}
                    errorMsg={(errors.scanWorkStatment && touched.scanWorkStatment) ? errors.scanWorkStatment : undefined}
                />
            </div>
        );
    }

    return (
        <div className={classes}>
            <div className='row align-items-end mb-1'>
                <div className='col-md-6 col-lg-4'>
                    <InputGroup
                        name={'carId'}
                        label={'מספר רכב'}
                        type='number'
                        value={values.carId}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        isRequired={true}
                        errorMsg={(errors.carId && touched.carId) ? errors.carId : undefined}
                    />
                </div>
                <div className='col-sm'>
                    <RadioBtnsContainer label={'בעלות רכב'}>
                        <RadioBtn
                            isChecked={radioBtnState[0]}
                            label='רכב פרטי'
                            value={OwnershipCarType.PRIVATE}
                            onChange={() => { }}
                            onClick={handleClick}
                            id="ownershipCarTypeYes"
                            name='ownershipCarType'
                        />
                        <RadioBtn
                            isChecked={radioBtnState[1]}
                            label='רכב חברה'
                            value={OwnershipCarType.COMPANY}
                            onChange={() => { }}
                            onClick={handleClick}
                            id="ownershipCarTypeNo"
                            name='ownershipCarType'
                        />
                    </RadioBtnsContainer>
                </div>

            </div>
            <div className='row mb-1'>
                <div className='col-md-6 col-lg-4'>
                    <InputGroup
                        type={'file'}
                        name={'scanCarId'}
                        label={'צילום רשיון רכב'}
                        handleBlur={handleBlur}
                        handleChange={(event) => {
                            setFieldValue('scanCarId', event.currentTarget.files[0])
                        }}
                        isRequired={true}
                        errorMsg={(errors.scanCarId && touched.scanCarId) ? errors.scanCarId : undefined}
                    />
                </div>
                {inputWorkStatment}
            </div>
            <div className='row'>
                <div className='col-md-6 col-lg-4'>
                    <SelectCarColor
                        name={'vColor'}
                        value={values.vColor}
                        isRequired={true}
                        handleChange={option => setFieldValue('vColor', option.value)}
                        handleBlur={e => {
                            handleBlur(e);
                            setFieldTouched('vColor', true);
                        }}
                        errorMsg={(errors.vColor && touched.vColor) ? errors.vColor : undefined}
                    />
                </div>
                <div className='col-md-6 col-lg-4'>
                    <SelectCarMaker
                        name={'vType'}
                        value={values.vType}
                        isRequired={true}
                        handleChange={option => setFieldValue('vType', option.value)}
                        handleBlur={e => {
                            handleBlur(e);
                            setFieldTouched('vType', true);
                        }}
                        errorMsg={(errors.vType && touched.vType) ? errors.vType : undefined}
                    />
                </div>
            </div>
        </div>
    );


    function handleClick(e) {
        setFieldValue('ownershipCarType', Number(e.currentTarget.value));
    }
}

CarDetails.defaultProps = {
    isChecked: false
}

export default CarDetails;