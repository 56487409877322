export const permitRequestData = {
    projectId:-1,
    permitRequestId: -1,
    asmachta: -1,
    amountPayed: 0,
    ownershipCarType: 1,
    agreeTakanon: 0,
    carId: '',
    vColor: -1,
    vType: -1,
    scanCarId: '',
    scanWorkStatment: null,
    agreeToVolunteer: false,
    agreeDataTransfer: false,
    permitType: 0,
    familyName: '',
    privateName: '',
    id: '',
    scanId: '',
    phone: '',
    mobile: '',
    house: '',
    appartment: '',
    streetId: '-1',
    email: '',
    additionalAddress: 0,
    mailRashumNum: '',
    mailCity: '',
    mailHouse: '',
    mailMikod: '',
    mailStreet: '',
    mailAppartment: '',
    cardNumber: '',
    cardExpireDateMM: '',
    cardExpireDateYY: '',
    cardAddedNumber: '',
    cardHolderId: '',
    extraFile1: null,
    extraFile2: null,
    extraFile3: null,
    extraFile4: null,
    extraFile5: null,
    isRentApp: 0,
    hasBusiness: 0,
}

export const permitRequestDummyData = {
    projectId:-1,
    permitRequestId: -1,
    asmachta: -1,
    amountPayed: 0,
    ownershipCarType: 2,
    agreeTakanon: 1,
    carId: '5245882',
    vColor: 100,
    vType: 100,
    scanCarId: '',
    scanWorkStatment: null,
    agreeToVolunteer: false,
    agreeDataTransfer: false,
    permitType: 0,
    familyName: 'dummy',
    privateName: 'data',
    id: '123456782',
    scanId: '',
    phone: '',
    mobile: '0507480014',
    house: '12',
    appartment: '',
    streetId: '-1',
    email: 'a@test.com',
    additionalAddress: 0,
    mailRashumNum: '',
    mailCity: '',
    mailHouse: '',
    mailMikod: '',
    mailStreet: '',
    mailAppartment: '',
    cardNumber: '',
    cardExpireDateMM: '',
    cardExpireDateYY: '',
    cardAddedNumber: '',
    cardHolderId: '',
    extraFile1: null,
    extraFile2: null,
    extraFile3: null,
    extraFile4: null,
    extraFile5: null,
    isRentApp: 0,
    hasBusiness: 0,
}